body {
  font: 14px "Century Gothic", 'Futura', 'sans-serif';
  margin:0px;
  background-image:
  url("https://s3-eu-west-1.amazonaws.com/jameskeiller.com/images/general/full-bloom.png")
/* Background pattern from Toptal Subtle Patterns  www.toptal.com/designers/subtlepatterns/

full-bloom.png
memphis-mini.png


*/
}

ol, ul {
  padding-left: 30px;
}

.flexRow{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.statsRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statsType{
  padding:5px;
}

.center{
  display: flex;
  flex-direction: column;
  align-items:center;
  padding-left:5%;
  padding-right:5%;
}

.board-played {
  margin-top:90px;
  clear: both;
  display: flex;
  flex-direction: column;
  align-items:center;
}
.board-played-row {
  width : 200px;
  height : 130px;
  margin-top: -90px;
  clear: both;
  display: flex;
  justify-content: space-evenly;
}

.board-dummy-row {

  margin-top: 25px;
  padding-top:20px;
  padding-left:25px;
  padding-right:25px;
  padding-bottom:135px;
  min-width :200px;
  flex-direction: row;
  content: "";
  display: flex;
  justify-content: space-evenly;
  align-items:start;
}

.board-highlight{
  box-shadow : 0px 0px 80px rgb(255, 255, 187);
  background: rgba(255, 255, 187, 0.45);
  border-radius: 100%;
}

.board-dummy-col {
  flex-direction: column;
  width :25%;
  content: "";
  display: flex;
  justify-content: center;
  align-items:center;
}

.board-row {
  clear: both;
  content: "";
  padding:5%;
  display: flex;
  justify-content: space-evenly;
}

.status {
  margin-bottom: 10px;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {

}

.backbutton:hover{
  background: lightgrey;
}

.littlebutton:hover{
  background: lightgrey;
}

.littlebutton{
background: #fff;
min-width:16px;
font-weight: bold;
text-align: center;
user-select: none;
cursor: pointer;
z-index: 1;
}


.backbutton{
background: #fff;
border: .5px solid lightgrey;
border-radius: 5px;
padding-left: 10px;
padding-right: 10px;
float: left;
font-size: 20px;
font-weight: bold;
line-height: 34px;
height: 34px;
margin-right: -1px;
margin-top: -1px;
text-align: center;
user-select: none;
cursor: pointer;
z-index: 1;
}

.game-info {
  margin-left: 20px;
}

.board-side {
  margin-left:25px;
  margin-right:25px;
  width:20%;
  max-width:200px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  height:95vh;
}

.board-center {
  flex-direction: column;
  display: flex;
  width: 60%;
  max-width:550px;
  justify-content: space-between;
  min-height: 95vh;
}

.board{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;

}

.red{
  color: #e60000
}

.trump{
  background:#FEF280;
}

.nontrump{
  background: white;
}



.carddummy{
  height:20px;
  width:40px;
  margin-left:-47px;
}

.cardhand{
  width :0;
  margin-left: -90px;
}

.emptycard{
  width:91px;
  min-height:131px;

}

.card{
  user-select: none;
  cursor: pointer;
  font-family: Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  width:90px;
  height:130px;
  border: .5px solid lightgrey;
  border-radius: 5px;

}
.cardhover {
  transition: margin-top .3s, transform .3s;
}

.cardhover:hover {
  z-index: 100;
  margin-top: -20px;
  transform: scale(1.1,1.1);
}

.card-side{
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: flex-start;
  width:20%;
  height:97%;
}
.card-picture{
  margin-left:-35%;
  margin-right:-35%;
  transform: scale(1,1.4);
}

.innercell{
  font-size:20px;
}

.vertcell{
  height:12%;
  font-size:14px;
}

.invert{
    transform: rotate(180deg);
}

.card-core{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items:center;
  width:65%;
  height:90%;
}

.card-core-sub{
  height:100%;
  width:30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:center;
}

.LevelContainer{
  width:100%;
  max-width:800px;
  height:800px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content :flex-start;
  flex-wrap:wrap;
}

.LevelCard{
  user-select: none;
  cursor: pointer;
  background: #fff;
  border: .5px solid lightgrey;
  border-radius: 5px;
  margin:3%;
  width:200px;
  height:26%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items :center;
}

.LevelInfo:hover{
    background: #ddd;
}


.LevelImage{
  width : 82%;
  border-width:1px;
  border-color: #888888;
  border-radius: 5px;
  border-style:solid;
  min-height : 50%;
  padding: 4%;
  background-color: #FFFFFF;
}

.LevelInfo{
  width : 82%;
  border-width:1px;
  border-color: #888888;
  border-radius: 5px;
  border-style:solid;
  min-height : 18%;
  padding: 4%;
  background-color: #FFFFFF;
}
